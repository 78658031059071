.App {
  background: url(../assets/background_plain.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
}

.App-error{
  color:white
}

.App-content{
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
}

.App-loader{
  position: absolute;
  background-color: rgba(50, 50, 50, 0.6);
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  color:white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Loader{
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 4vmax;
  height: 4vmax;
  margin-bottom: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}