.Item{
  display: flex;
  flex-direction: column;
  margin: 0;
  width: 13vmax;
  height: 13vmax;
  align-items: center;
  margin-left: .8vh;
  margin-right: .8vh;
}

.Item-image{
  height: 9vmax;
  width: 100%;
  display: block;
  overflow: hidden;
  word-wrap: break-word;
  color: rgb(157,150,140);
  text-align: center;
  border: 1px solid #444;
}

.Item-text{
  text-align: center;
  width: 100%;
  font-weight: 400;
  font-size: 0.95vmax;
  color: white;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}

.Item-default{
  display: grid;
  height: 70%;
  width: 100%;
  border: 1px solid #444;
  overflow: hidden;
  word-wrap: break-word;
}

.Item-default-image{
  height: 9vmax;
  width: 100%;
  grid-column: 1;
  grid-row: 1;
}

.Item-default-text{
  margin: 0;
  padding-bottom: 1%;
  color: rgb(17,49,90);
  grid-column: 1;
  grid-row: 1;
  text-align: center;
  text-shadow: 0px 0px 0px rgb(96,106,117);
  margin-bottom: 5%;
  margin-left: 10%;
  margin-right: 5%;
  align-self: flex-end;
  font-weight: 700;
  font-size: large;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden
}
