.Main {
  background: url(../../assets/background_top_line.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
}

.Main-body{
  margin-left: 2%;
  margin-right: 2%;
  margin-bottom: 2vh;
  margin-top: 1vh;
  height: 77vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  color: white;
}

.Main-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.Main-sorts{
  display: flex;
  flex-direction: row;
  height: 3vmax;
  margin-bottom: 2%;
}

.Main-sort-text{
  margin: 0;
  padding: 0;
  font-size: 1.5vmax;
  text-align: center;
  align-self: center;
}

.Main-sort-item{
  height: 100%;
  width: 10vmax;
  display: inline-grid;
  margin-left: 1vmax;
}

.Main-sort-item-image{
  height: 100%;
  width: 100%;
  grid-column: 1;
  grid-row: 1;
}

.Main-sort-item-text{
  width: 100%;
  margin: 0;
  padding-left: 10%;
  padding-bottom: 1%;
  color: black;
  text-align: center;
  align-self: center;
  grid-column: 1;
  grid-row: 1;
  font-weight: 700;
  font-size: 1.4vmax;
  text-transform: uppercase;
}

.Main-grid{
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-top: 2%;
}
