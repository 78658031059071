.VideoEdit {
  height: 100%;
  width: 50%;
  display: flex;
  margin-left: 4%;
  flex-direction: row;
  color: white;
  overflow: hidden;
}

.VideoEdit-left{
  height: 100%;
  width: 50%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.VideoEdit-left::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.VideoEdit-field-header{
  margin: 0;
  padding: 0;
  width: 100%;
}

.VideoEdit-input{
  margin-bottom: 0.5vmax;
  width:100%;
  display: block;
}


.VideoEdit-right{
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column; 
}

.VideoEdit-buttons{
  margin: 5%;
  padding: 1%;
}

.VideoEdit-sport-item{
  display:flex;
  flex-direction: row;
  margin-top: 1%;
}

.VideoEdit-sport-remove{
  margin-left: 2%;
}

.VideoEdit-thumbnail-preview{
  height:9vmax;
  width: 16vmax;
  display: block;
}

.VideoEdit-description{
  resize: vertical;
  margin-bottom: 0.5vmax;
  width:100%;
  display: block;
}