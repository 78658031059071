.Video {
  width: 96%;
  height: 100%;
  display: flex;
  margin-left: 4%;
  flex-direction: row;
  color: white;
  overflow: hidden;
}

.Video-left{
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Video-video{
  height: 85%;
}

.Video-video::-webkit-media-controls {
  display: none;
}

.Video-lower{
  height: 15%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content:left;
}

.Video-button-back{
  background: url("../../assets/button.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 1.5%;
  height: 45%;
  display: flex;
  flex-direction: row;
  padding-left: 3%;
  padding-right: 7%;
}

.Video-back-arrow{
  height: 40%;
  margin-right: 10%;
  margin-top: 2.5%;
  align-self: center;
}

.Video-button-text{
  color: black;
  align-self: center;
  flex-grow: 1;
  font-weight: 500;
  font-size: 1.5vmax;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
}

.Video-button-play{
  background: url("../../assets/button.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-top: 1.5%;
  height: 45%;
  display: flex;
  flex-direction: row;
  padding-left: 7%;
  padding-right: 7%;
  margin-left: 7%;
}

.Video-info{
  height: 100%;
  padding-left: 1%;
  display: flex;
  flex-direction: column;
}

.Video-text{
  margin: 0;
  padding: 0;
  font-size: 1vmax;
}

.Video-description{
  word-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  height:85%;
  font-weight: bold;
  font-size: .8vmax;
  flex-grow: 1;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.Video-description::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
