.ChooseEdit {
  background: url(../../assets/background_top_line.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
}

.ChooseEdit-body{
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 2vh;
  margin-top: 1vh;
  height: 77vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.ChooseEdit-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.ChooseEdit-item{
  display:flex;
  flex-direction: row;
  width: 100%;
}

.ChooseEdit-text{
  color: rgb(157,150,140);
}

.ChooseEdit-delete{
  background: url("../../assets/button.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 2vmax;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: 2%;
  align-self: center;
  display: flex;
  flex-direction: row;
  border: none;
}


.ChooseEdit-delete-text{
  align-self: center;
  text-align: center;
  color: black;
  font-size: 1vmax;
  padding-bottom: 5%;
  width: 100%;
}
