.HeaderEdit {
  height: 18.5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.HeaderEdit-icon{
  height: 50%;
  margin-left: 1%;
}

.HeaderEdit-title {
  color: rgb(157,150,140);
  margin-left: 1%;
  font-size: 4vh;
}

.HeaderEdit-title-edit {
  color: black;
  margin-left: 1%;
  font-size: 4vh;
  position: relative;
  margin-bottom: 4vh;
  flex-grow: 0.4;
}


.HeaderEdit-other{
  display: flex;
  flex-direction: row;
  height: 100%;
  flex-grow: 1;
  justify-content: flex-end;
  align-items: center;
}

.HeaderEdit-add{
  background: url("../../assets/button.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 20%;
  padding-left: 3%;
  padding-right: 3%;
  margin-bottom: 2%;
  margin-right: 1%;
  display: flex;
  flex-direction: row;
  border: none;
}

.HeaderEdit-save{
  background: url("../../assets/button.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 20%;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 2%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  border: none;
}

.HeaderEdit-button-text{
  align-self: center;
  text-align: center;
  color: black;
  font-size: 1vmax;
  padding-bottom: 5%;
  width: 100%;
}
