.Choose {
  background: url(../../assets/background_top_line.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
}

.Choose-body{
  margin-left: 5%;
  margin-right: 2%;
  margin-bottom: 2vh;
  margin-top: 1vh;
  height: 77vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.Choose-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.Choose-lower{
  color: rgb(157,150,140);
}
