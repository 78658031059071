.Category-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
}
.Category-items::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.Categroy-header{
  color: white;
  text-transform: uppercase;
  font-size: 1.6vmax;
  padding: 0;
  margin: 0;
  margin-bottom: .5vmax;
}
