.EditAuth {
  background: url(../../assets/background_plain.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.EditAuth-Form{
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
}
.EditAuth-Header{
  color: white;
  font-size: 2vmax;
  margin-bottom: 1%;
}

.EditAuth-Message{
  color: white;
  font-size: 1vmax;
  margin-bottom: 1%;
}


.EditAuth-Label{
  color: white;

}

.EditAuth-Input{
  margin-bottom: 1%;
}


.EditAuth-Loader{
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 4vmax;
  height: 4vmax;
  margin-bottom: 16px;
  animation: spin 2s linear infinite;
}

.EditAuth-Button{
  margin-bottom: 20px;
}