.ArticleEdit {
  background: url(../../assets/background_top_bottom_line.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
}

.ArticleEdit-body{
  height: 65.5vh;
  width: 100%;
  color: rgb(157,150,140);
  display: flex;
  flex-direction: row;
}

.ArticleEdit-info{
  height: 100%;
  width: 50%;
  word-wrap: break-word;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  font-weight: bold;
  font-size: large;
  flex-grow: 1;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
}

.ArticleEdit-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.ArticleEdit-footer{
  height: 16vh;
}

.light{
  color: rgb(157,150,140);
}