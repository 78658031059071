.CategoryEdit-items {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-x: scroll;
}
.CategoryEdit-items::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.CategroyEdit-header{
  color: white;
  text-transform: uppercase;
}
