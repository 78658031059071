.Article {
  background: url(../../assets/background_top_bottom_line.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-attachment: fixed;
  width: 100vw;
  height: 100vh;
  padding:0;
  margin:0;
  display: flex;
  flex-direction: column;
}

.Article-body{
  height: 65.5vh;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll; 
  color: rgb(157,150,140);
}

.Article-body::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.Article-footer{
  height: 16vh;
}

.light{
  color: rgb(157,150,140);
}