.Header {
  height: 18.5vh;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.Header-icon{
  height: 50%;
  margin-left: 1%;
}

.Header-title {
  color: rgb(157,150,140);
  margin-left: 1%;
  font-size: 4vh;
}

.Header-back{
  height: 100%;
  flex-grow: 1;
}